import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../utils/translation';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { displayDateDiff } from '../atoms/date/utils';
import Comment from '../icons/comment';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  sponsored: boolean;
  date: string;
  link: LinkProps;
  authors?: AuthorProp[];
  commentsNb?: number;
  large?: boolean;
  card?: boolean;
  mainTag?: string;
  hideMobileInformations?: boolean;
  minorShowcase?: boolean;
  fixedShowcase?: boolean;
}

const Title = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-family: var(--theme-typography-primary-font);
  font-weight: 700;
  font-size: 22px;
  line-height: 1.38;
  margin: var(--spacer) 0 var(--spacer-s);
  overflow: hidden;
  transition: color 0.3s ease;

  &.mod-minor {
    font-size: 18px;
    margin: var(--spacer-s) 0 var(--spacer-s);
  }

  &.mod-large {
    font-size: 26px;
  }

  .mod-card & {
    padding: 0 var(--spacer-s);
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 19px;

      &.mod-large {
        font-size: 19px;
      }
    `,
  )}
`;

const Post = styled.article`
  color: var(--theme-colors-foreground);
  display: inline-flex;
  flex-direction: column;
  position: relative;

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  picture {
    width: 100%;
  }

  &:hover ${Title} {
    color: var(--theme-color-primary);
  }

  &.mod-large {
    width: 100%;
  }

  &.mod-card {
    border-radius: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    min-height: 400px;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        border-radius: 6px;
      `,
    )}
  }

  &.mod-minor {
    min-height: 350px;
    max-height: 350px;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      img {
        object-fit: cover;
      }

      &.mod-card {
        min-height: initial;
      }
    `,
  )}
`;

const PublishDate = styled.span`
  color: var(--theme-colors-foreground);
`;

const Bottom = styled.div`
  align-items: center;
  display: flex;
  font-size: 13px;
  margin: auto 0 0;
  white-space: nowrap;

  .mod-card & {
    padding: 0 var(--spacer-s) var(--spacer-s);
  }

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      white-space: initial;
    `,
  )}
`;

const Informations = styled.span`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 12px;
  flex: 1;
  justify-content: flex-start;

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      &.mod-hidden-mobile {
        display: none;
      }
    `,
  )}
`;

const Comments = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;
`;

const Label = styled.div`
  align-self: start;
  background: rgba(0, 0, 0, 0.3);
  border: solid 2px white;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.62;
  margin: calc(-1 * var(--spacer-m)) var(--spacer-s) 0;
  padding: 3px 12px;
  text-transform: uppercase;
  z-index: 1;
`;

const MinorLabel = styled.div`
  align-self: start;
  background: rgba(0, 0, 0, 0.3);
  border: solid 2px white;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.62;
  padding: 3px 12px;
  text-transform: uppercase;
  z-index: 1;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      position: absolute;
      top: calc(180px - var(--spacer-fluid));
      left: var(--spacer-s);
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      position: absolute;
      top: var(--spacer-fluid);
      left: var(--spacer-s);
    `,
  )}
`;

const SponsorTag = styled.div`
  margin-left: var(--spacer-s);
`;

const getDesktopImagesProps = (large: boolean, minorShowcase: boolean, fixedShowcase: boolean) => {
  if (large) {
    return { width: 560, height: 301 };
  }

  if (minorShowcase) {
    return { width: 285, height: 180 };
  }

  if (fixedShowcase) {
    return { width: 354, height: 200 };
  }

  return { width: 372, height: 200 };
};

export const PostItem: any = (props: PostItemProps) => {
  const {
    image,
    title,
    date,
    link,
    authors,
    commentsNb,
    large,
    card,
    mainTag,
    sponsored,
    hideMobileInformations,
    minorShowcase,
    fixedShowcase,
  } = props;

  const t = useTranslation();
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();

  const desktopImagesProps = getDesktopImagesProps(large || false, minorShowcase || false, fixedShowcase || false);

  const imageProps = {
    ...image,
    mobileWidth: 313,
    mobileHeight: 204,
    desktopWidth: desktopImagesProps.width,
    desktopHeight: desktopImagesProps.height,
  };

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(date, locale);

  const classes = `${large ? 'mod-large' : ''} ${card ? 'mod-card' : ''} ${minorShowcase ? 'mod-minor' : ''}`;

  return (
    <Post className={classes}>
      {mainTag && minorShowcase ? <MinorLabel>{mainTag}</MinorLabel> : null}
      <LinkComponent {...link}>
        <ImageComponent {...imageProps} />
      </LinkComponent>
      {mainTag && !minorShowcase ? <Label>{mainTag}</Label> : null}
      <LinkComponent {...link}>
        <Title className={`${large ? 'mod-large' : ''} ${minorShowcase ? 'mod-minor' : ''}`} data-testid="meta-label">
          {title}
        </Title>
      </LinkComponent>
      <Bottom>
        <Informations className={hideMobileInformations ? 'mod-hidden-mobile' : ''}>
          <PublishDate data-testid="meta-date">{dateTimeAgo}</PublishDate>,
          {((minorShowcase && !sponsored) || fixedShowcase || (!minorShowcase && !fixedShowcase)) && (
            <>
              &nbsp;{t('design.postItem.authorLabel')}&nbsp;
              <strong>{authors}</strong>
            </>
          )}
          {!minorShowcase && sponsored && <SponsorTag>({t('design.postItem.sponsored')})</SponsorTag>}
          {minorShowcase && sponsored && <strong>&nbsp;{t('design.postItem.sponsored')}</strong>}
        </Informations>
        {commentsNb ? (
          <LinkComponent {...link}>
            <Comments>
              <Comment width={24} height={24} color="var(--theme-color-primary)" />
              {commentsNb}
            </Comments>
          </LinkComponent>
        ) : null}
      </Bottom>
    </Post>
  );
};
