import React from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { PostItem } from '@marty-js/design/src/molecules/post-item';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { Cta } from '@marty-js/design/src/molecules/cta';
import ArrowPlainRight from '@marty-js/design/src/icons/arrowPlainRight';
import { PostItemTitle } from '@marty-js/design/src/molecules/post-item-title';
import { postItemReducer } from '../../../../utils/listing';

export type ShowcaseAndCompactListingProps = {
  items: GenericItem[];
  title: string;
  spaceTop?: boolean;
  spaceBottom?: boolean;
  cta: {
    link: string;
    label: string;
  };
};

const ListingWrapper = styled.div`
  grid-column: 1 / 10;
  margin: 0 0 0;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-space-top {
        margin-top: var(--spacer-m-fluid);
      }

      &.mod-space-bottom {
        margin-bottom: var(--spacer-m-fluid);
      }
    `,
  )}

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: column;
      margin: 0 0 var(--spacer-m-fluid);
    `,
  )}
`;

const Title = styled.div`
  text-align: center;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer-fluid);
    `,
  )}
`;

const Items = styled.div`
  display: flex;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: column;
      gap: var(--spacer-s);
    `,
  )}
`;

const Featured = styled.div`
  background: var(--background-color);
  padding: 0 var(--spacer-l) 0 0;
  max-width: 50%;
  min-width: 50%;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      min-width: 100%;
      max-width: initial;
      padding: 0;
    `,
  )}
`;

const CtaContainer = styled.div`
  margin: var(--spacer-s-fluid) 0 0;
`;

export const ShowcaseAndCompactListing = ({
  items,
  title,
  cta,
  spaceTop,
  spaceBottom,
}: ShowcaseAndCompactListingProps) => {
  const featuredItem = items ? items[0] : null;
  const classes = [];

  if (spaceTop) {
    classes.push('mod-space-top');
  }

  if (spaceBottom) {
    classes.push('mod-space-bottom');
  }

  return (
    <ListingWrapper className={classes.join(' ')}>
      <Title>
        <HeadingTwo className="mod-without-mt mod-medium-black mod-huge">{title}</HeadingTwo>
      </Title>
      <Items>
        <Featured>{featuredItem && <PostItem {...postItemReducer({ item: featuredItem })} large />}</Featured>
        <div>
          {Array.isArray(items)
            ? items?.slice(1).map((item, index) => {
                return <PostItemTitle key={index} {...postItemReducer({ item })} />;
              })
            : null}
          <CtaContainer>
            <Cta href={cta.link} className="mod-blue mod-empty mod-icon mod-sm">
              {cta.label}
              <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
            </Cta>
          </CtaContainer>
        </div>
      </Items>
    </ListingWrapper>
  );
};
