import React from 'react';
import { LogoProps } from './types';

function Quote({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0333 23H19.3L22.6333 12.5088H16.6667V0H30V10.5154L26.0333 23ZM9.36667 23H2.63333L5.96667 12.5088H0V0H13.3333V10.5154L9.36667 23Z"
        fill={color}
      />
    </svg>
  );
}

export default Quote;
