import React from 'react';
import { GenericItem } from '@marty-js/api-sdk/types';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { MajorItemShowcase } from '@marty-js/design/src/molecules/showcase/major-item-showcase';
import { PostItem } from '@marty-js/design/src/molecules/post-item';
import { Cta } from '@marty-js/design/src/molecules/cta';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import dynamic from 'next/dynamic';
import { postItemReducer } from '../../../../utils/listing';

const ArrowPlainRight = dynamic(() => import('@marty-js/design/src/icons/arrowPlainRight'));

export type ShowcaseListingProps = {
  items: GenericItem[];
  split?: boolean;
  title?: string;
  cta?: {
    link?: string;
    label?: string;
  };
  insertAfterFirst?: any;
};

const ShowcaseListingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ShowcaseListingItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: var(--spacer-s);

  &.mod-fixed {
    gap: var(--spacer-m);
  }

  > * {
    flex: 1;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer);
      flex-direction: column;
      margin-bottom: 0;
    `,
  )}
`;

const LeftPart = styled.div`
  display: flex;
  gap: var(--spacer-s);
`;

const RightPart = styled.div`
  display: flex;
  gap: var(--spacer-s);

  > * {
    flex: 1;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer);
      flex-direction: column;
    `,
  )}
`;

const Title = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      text-align: center;
    `,
  )}
`;

const CtaWrapper = styled.div`
  grid-column: 1 / 7;
  margin: var(--spacer-fluid) 0 0;
  justify-content: center;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      > * {
        width: 100%;
      }
    `,
  )}
`;

const MobileAdInsert = styled.div`
  display: none;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: initial;
      margin-top: var(--spacer-s);
      margin-bottom: var(--spacer-s);
    `,
  )}
`;

export const ShowcaseListingInline = ({ items, split, title, cta, insertAfterFirst }: ShowcaseListingProps) => {
  if (!items || items?.length < 1) {
    return null;
  }

  const firstItem = items[0];
  const otherItems = items.slice(1, 3);

  if (split) {
    return (
      <ShowcaseListingWrapper>
        {title && (
          <Title>
            <HeadingTwo className="mod-medium-black mod-huge mod-without-mt">{title}</HeadingTwo>
          </Title>
        )}
        <ShowcaseListingItemsWrapper>
          <LeftPart>
            <MajorItemShowcase {...postItemReducer({ item: firstItem })} />
          </LeftPart>
          {insertAfterFirst ? <MobileAdInsert>{insertAfterFirst}</MobileAdInsert> : null}
          <RightPart>
            {otherItems.map((item: GenericItem, index: number) => (
              <PostItem key={index} {...postItemReducer({ item })} card minorShowcase />
            ))}
          </RightPart>
        </ShowcaseListingItemsWrapper>
        {cta?.link && cta?.label && (
          <CtaWrapper>
            <Cta href={cta.link} className="mod-blue mod-empty mod-icon mod-sm">
              {cta.label}
              <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
            </Cta>
          </CtaWrapper>
        )}
      </ShowcaseListingWrapper>
    );
  }

  return (
    <ShowcaseListingWrapper>
      {title && (
        <Title>
          <HeadingTwo className="mod-medium-black mod-huge mod-without-mt">{title}</HeadingTwo>
        </Title>
      )}
      <ShowcaseListingItemsWrapper className="mod-fixed">
        {items.map((item: GenericItem, index: number) => (
          <PostItem key={index} {...postItemReducer({ item })} card fixedShowcase />
        ))}
      </ShowcaseListingItemsWrapper>
      {cta?.link && cta?.label && (
        <CtaWrapper>
          <Cta href={cta.link} className="mod-blue mod-empty mod-icon mod-sm">
            {cta.label}
            <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
          </Cta>
        </CtaWrapper>
      )}
    </ShowcaseListingWrapper>
  );
};
