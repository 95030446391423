import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';

import { BottomNavCustom } from '@marty-js/design/src/molecules/bottom-navigation-custom';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { CommentCard } from '@marty-js/design/src/molecules/cards/comment';
import { Slider } from '@marty-js/design/src/molecules/slider';
import { Cta } from '@marty-js/design/src/molecules/cta';
import { PostItemMix } from '@marty-js/design/src/molecules/post-item-mix';
import { Select } from '@marty-js/design/src/atoms/filters/select';
import SwitchViewMode from '@marty-js/design/src/molecules/switch-view-mode';
import type { GenericItem } from '@marty-js/api-sdk/types';
import type { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import {
  Container,
  FullContent,
  FullContentRelative,
  MainContent,
  SideContent,
  SideContentSticky,
} from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { useSetMaxFirstImages } from '../../../utils/imageContext';
import type {
  BestComment,
  BestComments,
  HomepageFilters,
  Insert,
  InsertTabs,
  LayoutSectionProps,
  Trend,
  Trends,
} from '../types';
import { SelectedItemsLineTabs } from './partials/selected-items-line-tabs';
import { PostsList } from '../../../layout/post/posts-list';
import { generateItem, postItemReducer } from '../../../utils/listing';
import { ShowcaseAndCompactListing } from './partials/showcase-and-compact-listing';
import { NavLink } from '../../../atoms/nav-link';
import { displayDateDiff, getHistoryDate } from '../../../atoms/date/utils';
import { ShowcaseListingInline } from './partials/showcase-listing-inline';

const HomeIcon = dynamic(() => import('@marty-js/design/src/icons/material-symbols/home'));
const DealsIcon = dynamic(() => import('@marty-js/design/src/icons/material-symbols/deals'));
const NewsIcon = dynamic(() => import('@marty-js/design/src/icons/material-symbols/news'));

const ArrowPlainRight = dynamic(() => import('@marty-js/design/src/icons/arrowPlainRight'));

const Feed = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);

  ${mq.gte(
    1100,
    css`
      > *:nth-of-type(2) {
        overflow-y: auto;
        padding: 0 var(--spacer-s) 0 0;

        &::-webkit-scrollbar {
          background: var(--color-alt-3);
          border-radius: var(--spacer-xs);
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--background-color-alt);
          border: solid 2px var(--color-alt-3);
          border-radius: var(--spacer-xs);
        }
      }
    `,
  )}
`;

const TitleFeed = styled.div`
  ${mq.lte(
    1100,
    css`
      align-items: center;
      display: flex;
      justify-content: space-between;
    `,
  )}
`;

const CommentHeading = styled.div`
  text-align: center;
  grid-column: 1 / 10;
`;

const CommentBody = styled.div`
  grid-column: 1 / 10;
  margin: 0 0 var(--spacer-m-fluid);
`;

const TrendsElt = styled.div`
  display: flex;
  gap: var(--spacer-s);
  margin-bottom: var(--spacer-fluid);

  ${mq.lte(
    1100,
    css`
      overflow-x: auto;
      width: 100vw;
      margin: 0 0 var(--spacer-fluid) calc(-1 * var(--spacer-fluid));
      padding: 0 var(--spacer-fluid);
    `,
  )}
`;

const TrendElt = styled.span`
  border: 1px solid var(--theme-color-medium);
  border-radius: 30px;
  cursor: pointer;
  padding: var(--spacer-xs) var(--spacer-s);
  transition: background 0.3s, color 0.3s;
  white-space: nowrap;

  ${mq.lte(
    1100,
    css`
      margin-top: var(--spacer-s);
    `,
  )}

  &:hover {
    background-color: var(--text-color-alt);
    color: var(--theme-color-background);
  }
`;

const HeadingNews = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--spacer-s);
  justify-content: flex-start;
  width: 100%;
  margin-bottom: var(--spacer-s);
`;

const HeadingNewsLeft = styled.div`
  display: flex;
  gap: var(--spacer-s);
  flex-grow: 1;
  align-items: center;
`;

const AdContainer = styled.div`
  margin: 0 0 var(--spacer-fluid);
  width: 100%;
`;

const ContainerWithMargin = styled(Container)`
  margin-top: var(--spacer-m);
`;

const FullCommentRelative = styled(FullContentRelative)`
  ${mq.gte(
    1180,
    css`
      margin-top: calc(0px - var(--spacer-m));
    `,
  )}
`;

const CommentBackground = styled.div`
  background: var(--theme-color-background);
  bottom: 0;
  position: absolute !important;
  left: -15px;
  right: -15px;
  top: 0;
  z-index: 0 !important;
  max-width: 100vw;

  ${mq.gte(
    1180,
    css`
      right: calc(-1 * (100vw - 1180px) / 2);
      left: calc(-1 * (100vw - 1180px) / 2);
      max-width: initial;
    `,
  )}
`;

type HomepageMainFlux = {
  genericItemList?: GenericItem[];
  button?: string;
  title?: string;
  url?: string;
  info?: any;
};

export type HomepageData = {
  mainFlux: HomepageMainFlux;
  hero: {
    heros: {
      id: string;
      url: string;
      image: string;
      title: string;
      updatedAt?: string;
      comments?: number;
      tagSlugs?: string[];
      mainTag?: string;
    }[];
  };
  insert1: Insert;
  insert2: Insert;
  insert3: Insert;
  insertTab1: InsertTabs;
  insertTab2: InsertTabs;
  insertTab3: InsertTabs;
  bestComments?: BestComments;
  trends?: Trends;
  filters?: HomepageFilters;
};

const getItemListFromComponent = (comp: any): GenericItem[] => {
  if (comp?.dataComponent?.items) {
    return comp.dataComponent.items.genericItemList;
  }

  return comp.dataComponent.genericItems.genericItemList;
};

const Homepage: React.FC<LayoutSectionProps<HomepageData>> = ({ data, pageInfo }) => {
  const t = useTranslation();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const size = data.mainFlux?.info?.size ?? 20;
  const sectionMainFluxSortedBy = data?.mainFlux?.info?.sortBy;
  const [filterTag, setFilterTag] = useState<string>(null);
  const [mode, setMode] = useState<string>('large');
  useSetMaxFirstImages(5);
  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      tag: filterTag,
      size,
    }),
    [pageInfo?.section?.id, size, filterTag],
  );

  const { result, loading, fetchData } = useGetGenericItemBySection({ variables });

  useEffect(() => {
    if (variables.tag !== null) {
      fetchData({ variables });
    }
  }, [variables, variables.tag, fetchData]);

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems(result.genericItemBySection.genericItems);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);

  const { insertTab1, insertTab2, insertTab3 } = data;
  const { insert1, insert2, insert3 } = data;
  const mainFluxButton = {
    title: data.mainFlux.title,
    button: data.mainFlux.button,
    url: data.mainFlux.url,
  };

  const insert1items = getItemListFromComponent(insert1).slice(0, 3);
  const insert2items = getItemListFromComponent(insert2);
  const insert3items = getItemListFromComponent(insert3);

  const trendsComponent = data.trends;
  const trends = trendsComponent?.data?.links?.data || [];

  const heros = data?.hero?.heros || [];
  if (heros.length > 0 && !heros[0]?.tagSlugs?.includes('sponso')) {
    heros[0].mainTag = t('sdk.template.homepage.featured');
  }

  const bestComments = data?.bestComments?.bestComments || [];
  const formattedBestComments =
    bestComments
      .map((currentComment: BestComment, index): any => {
        const locale = t('locale');
        const dateTimeAgo = displayDateDiff(currentComment.publishedAt, locale);

        return (
          <CommentCard
            key={index}
            pseudo={currentComment?.author?.name}
            avatar={currentComment?.author?.imageUrl}
            comment={currentComment.comment}
            createdAt={dateTimeAgo}
            link={{ href: currentComment.item.url, title: currentComment.item.title }}
            title={currentComment.item.title}
          />
        );
      })
      .filter(Boolean) || [];

  const filters = data?.filters?.filters;
  let neverDisplayedTime = true;

  const mobileAdPos1 = useMemo(() => <Ad className="Mobile_Pos1" mobileOnly />, []);

  return (
    <>
      <Ad className="Billboard_1" desktopOnly megaban />
      <Container>
        <FullContent>
          <TrendsElt>
            {trends &&
              trends.length > 0 &&
              trends
                .filter((trend: Trend) => {
                  return trend?.url && trend.title;
                })
                .map((trend: Trend, index: number) => {
                  return (
                    <TrendElt key={index}>
                      <NavLink href={trend.url}>{trend.title}</NavLink>
                    </TrendElt>
                  );
                })}
          </TrendsElt>
        </FullContent>
      </Container>
      <Container>
        <FullContent>
          <ShowcaseListingInline items={heros} split insertAfterFirst={mobileAdPos1} />
        </FullContent>
      </Container>
      <ContainerWithMargin>
        <MainContent>
          <Feed>
            <TitleFeed>
              <HeadingNews>
                <HeadingNewsLeft>
                  <HeadingTwo className="mod-huge mod-medium-black mod-without-m">
                    <NavLink href={mainFluxButton.url}>
                      {mainFluxButton?.title ? mainFluxButton.title : t('sdk.template.main_listing.headline')}
                    </NavLink>
                  </HeadingTwo>
                  {filters?.length > 0 && (
                    <Select
                      options={filters.map((filter) => {
                        return { id: filter.tagSlug, value: filter.label };
                      })}
                      onChange={(values) => {
                        setFilterTag(values[0]);
                      }}
                      multiple={false}
                      labelledBy="homepage-filter"
                      allLabel="Filtrer"
                    />
                  )}
                </HeadingNewsLeft>
                <SwitchViewMode
                  sectionId={pageInfo?.section.id}
                  defaultMode={mode}
                  allDevices
                  callback={(newMode: string) => setMode(newMode)}
                />
              </HeadingNews>
            </TitleFeed>
            <PostsList>
              {genericItems?.slice(0, 20).map((genericItem: GenericItem, index: number) => {
                let previousDate = null;
                let currentDate = null;
                if (sectionMainFluxSortedBy === 'republishedAt') {
                  previousDate = index > 0 ? new Date(genericItems[index - 1].republishedAt) : null;
                  currentDate = new Date(genericItem.republishedAt);
                } else {
                  previousDate = index > 0 ? new Date(genericItems[index - 1].updatedAt) : null;
                  currentDate = new Date(genericItem.updatedAt);
                }
                const label = getHistoryDate(currentDate, previousDate, t('locale'));
                if (neverDisplayedTime && label) {
                  neverDisplayedTime = false;
                }

                const displayTime = !!neverDisplayedTime;

                if (mode === 'large') {
                  return generateItem(genericItem, index, t, displayTime, sectionMainFluxSortedBy);
                }

                return (
                  <PostItemMix
                    key={index}
                    className={index === 0 && !label ? 'mod-first' : ''}
                    mode={mode}
                    separator={label}
                    {...postItemReducer({ item: genericItem, sortBy: sectionMainFluxSortedBy })}
                  />
                );
              })}
            </PostsList>
            {mainFluxButton.url && (
              <Cta href={mainFluxButton.url} className="mod-blue mod-empty mod-icon mod-sm">
                {mainFluxButton?.button ? mainFluxButton.button : t('sdk.template.main_listing.all_news')}
                <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
              </Cta>
            )}
          </Feed>
        </MainContent>
        <SideContent>
          <SideContentSticky>
            <Ad className="HalfpageAd_1" desktopOnly />
          </SideContentSticky>
        </SideContent>
      </ContainerWithMargin>

      <ContainerWithMargin>
        <FullContent>
          <ShowcaseListingInline
            items={insert1items}
            title={insert1?.title}
            cta={{ link: insert1?.url, label: insert1?.button }}
          />
        </FullContent>
      </ContainerWithMargin>

      {insertTab1 ? (
        <ContainerWithMargin>
          <SelectedItemsLineTabs mainTitle={`${insertTab1.mainTitle}`} tabs={insertTab1.resolvedTabs} />
        </ContainerWithMargin>
      ) : null}
      <FullContent>
        <AdContainer>
          <Ad className="Mobile_Pos2" mobileOnly />
          <Ad className="Billboard_2" desktopOnly />
        </AdContainer>
      </FullContent>
      <ContainerWithMargin>
        <ShowcaseAndCompactListing
          items={insert2items}
          title={insert2?.title}
          cta={{ link: insert2?.url, label: insert2?.button }}
          spaceBottom
        />
      </ContainerWithMargin>

      {insertTab2 ? (
        <ContainerWithMargin>
          <SelectedItemsLineTabs mainTitle={`${insertTab2.mainTitle}`} tabs={insertTab2.resolvedTabs} />
        </ContainerWithMargin>
      ) : null}
      <ContainerWithMargin>
        <ShowcaseAndCompactListing
          items={insert3items}
          title={insert3?.title}
          cta={{ link: insert3?.url, label: insert3?.button }}
          spaceBottom
        />
      </ContainerWithMargin>

      {insertTab3 ? (
        <ContainerWithMargin>
          <SelectedItemsLineTabs mainTitle={`${insertTab3.mainTitle}`} tabs={insertTab3.resolvedTabs} />
        </ContainerWithMargin>
      ) : null}

      <FullCommentRelative>
        <CommentBackground />
        <CommentHeading>
          <HeadingTwo className="mod-medium-black mod-huge mod-without-mt">{t('sdk.comments.bestComments')}</HeadingTwo>
        </CommentHeading>
        <CommentBody>
          <Slider items={formattedBestComments} />
        </CommentBody>
      </FullCommentRelative>

      <BottomNavCustom
        navigations={[
          {
            icon: <HomeIcon width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.home'),
            type: 'href',
            href: '/',
            onClick: () => {},
          },
          {
            icon: <NewsIcon width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.news'),
            type: 'href',
            href: '/actualites-informatique/',
            onClick: () => {},
          },
          {
            icon: <DealsIcon width={22} height={22} color="var(--title-foreground)" />,
            label: t('design.bottomNav.deals'),
            type: 'href',
            href: '/bons-plans/',
            onClick: () => {},
          },
        ]}
      />
    </>
  );
};

export default Homepage;
