import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { Cta } from './cta';

export interface SliderProps {
  items: React.ReactNode[];
  bottomLink?: {
    href: string;
    label: string;
  };
}

const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  gap: 20px;
  margin: 0 -20px;
  overflow: auto hidden;
  padding-left: 20px;
  width: 100vw;

  &::-webkit-scrollbar {
    display: none;
  }

  &.mod-active {
    cursor: grab;

    a {
      pointer-events: none;
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 0 calc(-1 * (100vw - 1180px) / 2);
      padding: 0;
    `,
  )}
`;

const SliderEmpty = styled.div`
  display: none;
  height: 360px;
  min-width: calc((100vw - 1220px) / 2);
  width: calc((100vw - 1220px) / 2);

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const SliderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Controls = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: wrap;
  gap: 7px;
  justify-content: center;
`;

const Border = styled.div`
  box-sizing: border-box;
  padding: 5px 7px;
`;

const Bullet = styled.div`
  background: #869791;
  border-radius: 20px;
  height: 12px;
  opacity: 0.5;
  width: 12px;

  &.mod-selected {
    opacity: 1;
  }
`;

const SliderMore = styled.div`
  margin: 30px 0 0;
  text-align: center;
`;

export const Slider = (props: SliderProps) => {
  const [bullets, setBullets] = useState(0);
  const [bulletSelected, setBulletSelected] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const sliderRef = useRef(null);

  const { items, bottomLink } = props;

  const updateWindowSize = useCallback(() => {
    setBullets(Math.ceil(sliderRef.current.scrollWidth / sliderRef.current.clientWidth));
  }, [sliderRef]);

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    updateWindowSize();

    return () => window.removeEventListener('resize', updateWindowSize);
  }, [updateWindowSize]);

  const onSliderScroll = useCallback(() => {
    const sliderElement = sliderRef.current;
    const percentage = (100 * sliderElement.scrollLeft) / (sliderElement.scrollWidth - sliderElement.clientWidth);

    if (percentage === 100) {
      setBulletSelected(bullets - 1);
    } else {
      setBulletSelected(Math.floor((percentage * bullets) / 100));
    }
  }, [bullets]);

  const onSelectBullet = useCallback(
    (index: number) => {
      sliderRef.current.style.scrollBehavior = 'smooth';
      sliderRef.current.scrollTo((sliderRef.current.scrollWidth / bullets) * index, 0);
      sliderRef.current.style.scrollBehavior = 'initial';
    },
    [sliderRef, bullets],
  );

  const end = useCallback(
    (e: any) => {
      setIsDown(false);

      const x = e.pageX || e.touches[0].pageX - sliderRef.current.offsetLeft;
      const dist = x - startX;

      if (dist === 0) {
        if (e.target.tagName === 'A') {
          e.target.click();
        } else {
          e.target.querySelector('a')?.click();
        }
      }
    },
    [startX, sliderRef],
  );

  const start = useCallback(
    (e: any) => {
      setStartX(e.pageX || e.touches[0].pageX - sliderRef.current.offsetLeft);
      setScrollLeft(sliderRef.current.scrollLeft);
      setIsDown(true);
    },
    [sliderRef],
  );

  const move = useCallback(
    (e: any) => {
      if (!isDown) {
        return;
      }

      e.preventDefault();
      const x = e.pageX || e.touches[0].pageX - sliderRef.current.offsetLeft;
      const dist = x - startX;
      sliderRef.current.scrollLeft = scrollLeft - dist;
    },
    [isDown, startX, scrollLeft, sliderRef],
  );

  return (
    <>
      <Wrapper
        className={isDown ? 'mod-active' : ''}
        ref={sliderRef}
        onScroll={() => onSliderScroll()}
        onMouseDown={(e) => start(e)}
        onMouseMove={(e) => move(e)}
        onMouseUp={(e) => end(e)}
      >
        <SliderEmpty />
        {items?.map((item, index) => (
          <SliderItem key={index}>{item}</SliderItem>
        ))}
      </Wrapper>
      <Controls>
        {bullets > 0 &&
          [...Array(bullets)].map((value, index) => (
            <Border key={index} onClick={() => onSelectBullet(index)}>
              <Bullet className={bulletSelected === index ? 'mod-selected' : ''} />
            </Border>
          ))}
      </Controls>
      {bottomLink?.href && (
        <SliderMore>
          <Cta className="mod-blue mod-empty mod-inline" {...bottomLink}>
            {bottomLink.label}
          </Cta>
        </SliderMore>
      )}
    </>
  );
};
