import React from 'react';
import styled, { css } from 'styled-components';
import { Stars } from './stars';
import { DEVICE_SIZE, mq } from '../utils/mq';

export interface StarsMarkProps {
  rating: number;
  ratingLabel?: number;
  max: number;
  maxLabel?: number;
}

const Mark = styled.div`
  display: flex;
  gap: 6px;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      gap: 15px;
    `,
  )}
`;

const MarkStars = styled.div`
  display: flex;
`;

const MarkText = styled.span`
  align-items: end;
  color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.black)};
  display: flex;
  font-size: 16px;
`;

const MarkTextValue = styled.span`
  font-weight: 700;
`;

const MarkTextTotal = (props: React.HTMLAttributes<HTMLSpanElement>) => <span {...props} />;

export const StarsMark = (props: StarsMarkProps) => {
  const { rating, ratingLabel, max, maxLabel } = props;

  return (
    <Mark>
      <MarkStars>
        <Stars rating={rating} max={max} />
      </MarkStars>
      <MarkText>
        <MarkTextValue>{ratingLabel || rating} </MarkTextValue>
        <MarkTextTotal>/{maxLabel || max}</MarkTextTotal>
      </MarkText>
    </Mark>
  );
};
