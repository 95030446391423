import React from 'react';
import styled, { css } from 'styled-components';
import { useImageComponent, useLinkComponent } from '../../../utils/component';
import { Cta } from '../../cta';
import type { LinkProps } from '../../../atoms/types';
import { StarsMark } from '../../stars-with-mark';
import { DEVICE_SIZE, mq } from '../../../utils/mq';
import { useTranslation } from '../../../utils/translation';

export type CardWithAuthorProps = {
  title?: string;
  rating?: number;
  ratingNb?: number;
  image: { imageId: string; alt: string; extension?: string; title?: string };
  itemLink?: LinkProps;
  ctaLink?: LinkProps;
  reviewLink?: LinkProps;
  author?: {
    id?: string;
    title?: string;
    imageId?: string;
    description?: string;
  };
  price?: string;
  dominantLayout?: string;
};

const Card = styled.div`
  align-self: stretch;
  background: ${(props) => props.theme.colors.background};
  color: ${(props) => (props.theme.isDark ? 'white' : props.theme.palette.blackAlt)};
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  padding: var(--spacer-s) var(--spacer-fluid) var(--spacer-fluid);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      max-width: 100%;
      flex-direction: row;
      width: 100%;
    `,
  )}
`;

const CardImage = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  pointer-events: none;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      border: none;
      min-width: 60px;
    `,
  )}
`;

const CardTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.38;
  min-height: 2.76em;
  margin: 0 0 auto;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-decoration: none;
  text-underline-offset: 2px;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0;
      min-height: initial;
      text-align: left;
    `,
  )}
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s-fluid);
`;

const CardStarsMark = styled.div`
  align-items: center;
  display: flex;
  align-self: center;
  gap: var(--spacer-xs);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      align-self: flex-start;
    `,
  )}
`;

const CardCta = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const AuthorImage = styled.div`
  border-radius: 50%;
  height: 45px;
  max-width: 45px;
  min-width: 45px;
  overflow: hidden;
  width: 45px;
`;

const Author = styled.div`
  align-items: center;
  display: flex;
  margin: var(--spacer-xs) 0 0;
  gap: 15px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const By = styled.div`
  font-size: 13px;
  line-height: 1.62;
`;

const AuthorSummary = styled.div`
  font-size: 13px;
  line-height: 1.62;
`;

interface CardWithAuthorType {
  handles: (dominantLayout: string) => boolean;
  Component: React.FC<CardWithAuthorProps>;
}

const CardWithAuthorDownload: React.FC<CardWithAuthorProps> = (props) => {
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const { image, title, itemLink, rating } = props;

  const imageProps = {
    ...image,
    mobileWidth: 60,
    mobileHeight: 60,
    desktopWidth: 212,
    desktopHeight: 212,
    disableRadius: true,
  };

  return (
    <Card>
      <CardImage>
        <LinkComponent {...itemLink}>
          <ImageComponent {...imageProps} />
        </LinkComponent>
      </CardImage>
      <CardBody>
        {itemLink?.href && (
          <CardTitle title={title}>
            <LinkComponent {...itemLink}>{title}</LinkComponent>
          </CardTitle>
        )}
        {itemLink?.href && (
          <CardCta>
            <Cta className="mod-sm mod-green" {...itemLink}>
              {itemLink.title}
            </Cta>
          </CardCta>
        )}
        {rating ? (
          <CardStarsMark>
            <StarsMark rating={rating / 2} max={5} ratingLabel={rating} maxLabel={10} />
          </CardStarsMark>
        ) : null}
      </CardBody>
    </Card>
  );
};

const CardWithAuthorDefault: React.FC<CardWithAuthorProps> = (props) => {
  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const t = useTranslation();
  const { image, title, itemLink, ctaLink, reviewLink, rating, author } = props;

  const imageProps = {
    ...image,
    mobileWidth: 60,
    mobileHeight: 60,
    desktopWidth: 212,
    desktopHeight: 212,
    disableRadius: true,
  };

  const authorImageProps = {
    imageId: author?.imageId,
    alt: author?.title,
    mobileWidth: 45,
    mobileHeight: 45,
    desktopWidth: 45,
    desktopHeight: 45,
  };

  return (
    <Card>
      <CardImage>
        <LinkComponent {...itemLink}>
          <ImageComponent {...imageProps} />
        </LinkComponent>
      </CardImage>
      <CardBody>
        {itemLink?.href && (
          <CardTitle title={title}>
            <LinkComponent {...itemLink}>{title}</LinkComponent>
          </CardTitle>
        )}
        <CardCta>
          {reviewLink?.href && (
            <Cta className="mod-sm mod-blue" {...reviewLink}>
              {reviewLink.label}
            </Cta>
          )}
          {!reviewLink?.href && itemLink?.href && (
            <Cta className="mod-sm mod-blue" {...itemLink}>
              {itemLink.title}
            </Cta>
          )}
          {!reviewLink?.href && !itemLink?.href && ctaLink?.href && (
            <Cta className="mod-sm mod-blue" {...ctaLink}>
              {ctaLink.title}
            </Cta>
          )}
        </CardCta>
        {rating ? (
          <CardStarsMark>
            <StarsMark rating={rating / 2} max={5} ratingLabel={rating} maxLabel={10} />
          </CardStarsMark>
        ) : null}
        {author?.title ? (
          <Author>
            {author?.imageId && (
              <AuthorImage>
                <ImageComponent {...authorImageProps} />
              </AuthorImage>
            )}
            <div>
              <By>
                {t('design.withAuthorCard.by')}{' '}
                <strong>
                  {author.title}
                  {author?.description && ','}
                </strong>
              </By>
              {author?.description && <AuthorSummary>{author.description}</AuthorSummary>}
            </div>
          </Author>
        ) : null}
      </CardBody>
    </Card>
  );
};

const cardWithAuthorTypes: CardWithAuthorType[] = [
  {
    handles: (dominantLayout: string) => {
      return dominantLayout === 'product';
    },
    Component: CardWithAuthorDefault,
  },
  {
    handles: (dominantLayout: string) => {
      return dominantLayout === 'download';
    },
    Component: CardWithAuthorDownload,
  },
  {
    handles: (dominantLayout: string) => {
      return dominantLayout === 'online-service';
    },
    Component: CardWithAuthorDownload,
  },
  {
    handles: (dominantLayout: string) => {
      return !['product', 'online-service', 'download'].includes(dominantLayout);
    },
    Component: CardWithAuthorDefault,
  },
];

export const CardWithAuthor: React.FC<CardWithAuthorProps> = (props) => {
  const { dominantLayout } = props;

  const CardFC = cardWithAuthorTypes.find((card) => card.handles(dominantLayout));
  if (CardFC) {
    return <CardFC.Component {...props} />;
  }

  return null;
};
