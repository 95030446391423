import React, { useCallback, useRef } from 'react';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import styled, { css } from 'styled-components';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { Cta } from '@marty-js/design/src/molecules/cta';
import ArrowPlainRight from '@marty-js/design/src/icons/arrowPlainRight';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import debounce from 'lodash.debounce';
import { CardWithAuthor } from '@marty-js/design/src/molecules/cards/product/with-author';
import { TranslationType, useTranslation } from '@marty-js/design/src/utils/translation';
import ArrowDown from '@marty-js/design/src/icons/arrowDown';
import type { LinkProps } from '@marty-js/design/src/atoms/types';
import type { GenericItem } from '@marty-js/api-sdk/types';
import { Background, Container, FullContentRelative } from '../../../../atoms/grid-container';
import { InsertTab } from '../../types';

export type SelectedItemsLineTabsProps = {
  mainTitle: string;
  tabs: InsertTab[];
};

type Details = {
  ctaLink?: LinkProps;
  reviewLink?: LinkProps;
  itemLink?: LinkProps;
  title?: string;
  rating?: number;
  ratingNb?: number;
  author?: any;
  imageId?: string;
};

const SelectedItemsLineContainer = styled(Container)`
  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      margin: 0 0 var(--spacer);
    `,
  )}
`;

const Tabs = styled.div`
  display: flex;
  gap: var(--spacer);
  margin: var(--spacer-fluid) 0 20px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      width: 100%;
    `,
  )}
`;

const TabButtonPrevious = styled.span`
  display: none;
  margin-right: var(--spacer-xs);
  position: relative;
  rotate: 90deg;
  z-index: 1;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const TabButtonNext = styled.span`
  display: none;
  margin-left: var(--spacer-xs);
  position: relative;
  rotate: -90deg;
  z-index: 1;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
    `,
  )}
`;

const TabButton = styled.span`
  border-bottom: solid 1px var(--theme-color-foreground);
  color: var(--theme-color-foreground);
  cursor: pointer;
  font-size: 18px;
  line-height: 1.38;
  padding: 0 0 3px;
  transition: opacity 0.3s;

  &.mod-active {
    font-weight: 600;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      border: solid 1px var(--theme-color-foreground);
      border-radius: var(--spacer);
      display: none;
      font-size: 16px;
      padding: var(--spacer-xs) var(--spacer);
      text-align: center;
      width: 100%;

      &.mod-active {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    `,
  )}
`;

const TabBody = styled.div`
  display: none;

  &.mod-visible {
    align-items: center;
    display: flex;
    flex-direction: column;

    ${mq.lte(
      DEVICE_SIZE.LARGE,
      css`
        width: 100%;
      `,
    )}
  }
`;

const WrapperItems = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacer-fluid);
  margin: var(--spacer-s) 0 var(--spacer);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0;
      flex-direction: column;
      padding: 0 0 var(--spacer-s);
      width: 100%;
    `,
  )}
`;

const SelectedItemsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: grab;
`;

const TabsWrapper = styled.div`
  align-self: stretch;
  left: 0;
  right: 0;
  position: relative;
  transition: right 0.05s, left 0.05s;

  &.mod-shift-left {
    left: calc(-1 * var(--spacer));
    right: initial;
  }

  &.mod-shift-right {
    right: calc(-1 * var(--spacer));
    left: initial;
  }
`;

const queryItems = (tab: InsertTab) => {
  return tab.componentData.genericItems.genericItemList;
};

const itemSelectionItems = (tab: InsertTab) => {
  return tab.componentData.items.genericItemList;
};

const automaticItems = (tab: InsertTab) => {
  return tab.componentData.items.genericItemList;
};

const getItemsFromTab = (tab: InsertTab) => {
  switch (tab?.type) {
    case 'query':
      return queryItems(tab);
    case 'item_selection':
      return itemSelectionItems(tab);
    case 'automatic_best_product':
      return automaticItems(tab);
    default:
      return null;
  }
};

const getCurrentAuthor = (item: GenericItem): any => {
  switch (item.layout) {
    case 'online-service':
    case 'download':
      return null;
    case 'product':
      return item?.review?.authors?.length > 0 ? item.review.authors[0] : null;
    case 'service-review':
    case 'download-review':
    case 'product-review':
    default:
      return item.authors?.length > 0 ? item.authors[0] : null;
  }
};

const getDetailsFromGenericItem = (item: GenericItem, t: TranslationType): Details => {
  const currentAuthor = getCurrentAuthor(item);
  const ctaLink = item?.price?.link
    ? {
        href: item.price.link,
        title: t(`sdk.template.main_listing.best_product.ctaLabel.${item?.layout}`),
        openInNewWindow: false,
      }
    : null;

  let reviewLink;
  let itemLink;
  let title;
  let imageId;
  let rating;
  let ratingNb;

  switch (item.layout) {
    case 'online-service':
    case 'download':
      reviewLink = null;

      itemLink = {
        href: item.url,
        title: t(`sdk.template.main_listing.best_product.itemLinkLabel.${item.layout}`),
      };

      title = item.title;
      rating = item.userRating / 10;
      ratingNb = item.userRatingNb;

      imageId = item.imageId;
      break;
    case 'plan':
    case 'product':
      if (item?.review) {
        reviewLink = {
          href: item.review.url,
          title: `${t(`sdk.template.main_listing.best_product.reviewLabel.${item.review?.layout}`)} ${item.title}`,
          label: t(`sdk.template.main_listing.best_product.reviewLabel.${item.review?.layout}`),
        };
      } else {
        reviewLink = null;
      }

      itemLink = {
        href: item.url,
        title: t(`sdk.template.main_listing.best_product.itemLinkLabel.${item.layout}`),
      };

      title = item.title;
      try {
        const rawRating = JSON.parse(item.productReviewScore);
        rating = rawRating?.globalScore;
      } catch (e) {
        rating = null;
      }

      imageId = item.imageId;

      break;
    case 'download-review':
    case 'service-review':
    case 'product-review':
      if (item?.reviewedItem) {
        reviewLink = {
          href: item.url,
          title: `${t(`sdk.template.main_listing.best_product.reviewLabel.${item.layout}`)} ${item.title}`,
          label: t(`sdk.template.main_listing.best_product.reviewLabel.${item.layout}`),
        };

        itemLink = {
          href: item.reviewedItem.url,
          title: t(`sdk.template.main_listing.best_product.itemLinkLabel.product`),
        };
      } else {
        reviewLink = {
          href: item.url,
          title: `${t(`sdk.template.main_listing.best_product.reviewLabel.${item.layout}`)} ${item.title}`,
          label: t(`sdk.template.main_listing.best_product.reviewLabel.${item.layout}`),
        };

        itemLink = {
          href: item.url,
          title: t(`sdk.template.main_listing.best_product.itemLinkLabel.product`),
        };
      }

      if (item?.reviewedItem) {
        title = item.reviewedItem.title;
        imageId = item.reviewedItem.imageId;
      } else {
        title = item.title;
        imageId = item.imageId;
      }

      try {
        const rawRating = JSON.parse(item.productReviewScore);
        rating = rawRating?.globalScore;
      } catch (e) {
        try {
          const rawReviewedItemRatig = JSON.parse(item.reviewedItem.ratingData);
          rating = rawReviewedItemRatig.globalScore;
        } catch (f) {
          rating = null;
        }
      }
      break;

    default:
      title = item.title;
      imageId = item.imageId;
      itemLink = {
        href: item.url,
        title: t(`sdk.template.main_listing.best_product.reviewLabel.default`),
      };
      break;
  }

  return {
    author: currentAuthor,
    reviewLink,
    itemLink,
    ctaLink,
    title,
    imageId,
    rating,
    ratingNb,
  };
};

export const SelectedItemsLineTabs = ({ mainTitle, tabs }: SelectedItemsLineTabsProps) => {
  const { currentTheme } = useThemeSwitcher();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const selectedItemsRef = useRef(null);
  const tabBodyRef = useRef(null);

  const t = useTranslation();

  const [hasSwiped, setHasSwiped] = React.useState(false);
  const startX = useRef<number>(0);

  const handleTouchStart = useCallback((event: any) => {
    if (event.type === 'touchstart') {
      startX.current = event.touches[0].clientX;
    } else if (event.type === 'mousedown') {
      startX.current = event.clientX;
    }
    setHasSwiped(false);
  }, []);

  const moveToTheLeft = useCallback(() => {
    setSelectedTab(selectedTab >= 1 ? selectedTab - 1 : selectedTab);
    tabBodyRef.current.classList.add('mod-shift-right');
    setTimeout(() => {
      tabBodyRef.current.classList.remove('mod-shift-right');
    }, 150);
  }, [selectedTab]);

  const moveToTheRight = useCallback(() => {
    setSelectedTab(selectedTab + 2 <= tabs.length ? selectedTab + 1 : selectedTab);
    tabBodyRef.current.classList.add('mod-shift-left');
    setTimeout(() => {
      tabBodyRef.current.classList.remove('mod-shift-left');
    }, 150);
  }, [selectedTab, tabs.length]);

  const handleTouchEnd = useCallback(
    (event: any) => {
      if (hasSwiped) {
        return;
      }

      let endX = 0;
      if (event.type === 'touchend') {
        endX = event.changedTouches ? event.changedTouches[0].clientX : event.touches[0].clientX;
      } else if (event.type === 'mouseup') {
        endX = event.clientX;
      }

      const diffX = endX - startX.current;

      if (diffX > 30) {
        moveToTheLeft();
      } else if (diffX < -30) {
        moveToTheRight();
      }

      setHasSwiped(true);
    },
    [hasSwiped, moveToTheLeft, moveToTheRight],
  );

  const handleWheel = debounce(async (event) => {
    if (event.deltaY !== 0) {
      return;
    }

    if (event.deltaX > 0) {
      moveToTheRight();
    } else {
      moveToTheLeft();
    }
  }, 100);

  if (!tabs || !tabs.length) {
    return null;
  }

  const formatItems = (items: GenericItem[]) => {
    if (!items) {
      return null;
    }

    return items.map((currentItem: GenericItem, index: number) => {
      if (!currentItem?.title) {
        return null;
      }

      const details = getDetailsFromGenericItem(currentItem, t);

      return (
        <CardWithAuthor
          key={index}
          title={details.title}
          ctaLink={details.ctaLink}
          itemLink={details.itemLink}
          reviewLink={details.reviewLink}
          image={{ imageId: details.imageId, alt: details.title }}
          rating={details.rating}
          ratingNb={details?.ratingNb}
          author={details.author}
          dominantLayout={currentItem.layout}
        />
      );
    });
  };

  // case section has no config, api did an automatic tab.
  let sanitizedMainTitle = mainTitle;
  if (mainTitle === 'popular_automatic') {
    const onlyTab = tabs[0];
    const sectionTitle = tabs[0].title;
    const dominantLayout = onlyTab?.componentData?.items?.dominantLayout;

    sanitizedMainTitle = t(`sdk.template.main_listing.best_product.automatic_main_title.default`, {
      section: sectionTitle,
    });

    if (dominantLayout) {
      if (['download', 'online-service', 'product'].includes(dominantLayout)) {
        sanitizedMainTitle = t(`sdk.template.main_listing.best_product.automatic_main_title.${dominantLayout}`, {
          section: sectionTitle,
        });
      }
    }
  }

  return (
    <SelectedItemsLineContainer>
      <FullContentRelative>
        <Background />
        <SelectedItemsWrapper
          ref={selectedItemsRef}
          onWheel={handleWheel}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleTouchStart}
          onMouseUp={handleTouchEnd}
        >
          {typeof mainTitle !== 'undefined' && (
            <HeadingTwo className="mod-medium-black mod-huge mod-without-m">{sanitizedMainTitle}</HeadingTwo>
          )}
          {tabs?.length > 1 && (
            <Tabs>
              {tabs?.map((tab: InsertTab, index: number) => {
                return (
                  <TabButton
                    key={`tab-button-${index}`}
                    onClick={() => setSelectedTab(index)}
                    className={index === selectedTab ? 'mod-active' : ''}
                  >
                    <TabButtonPrevious
                      onClick={(e: any) => {
                        if (index === 0) {
                          return;
                        }
                        e.stopPropagation();
                        setSelectedTab(index - 1);
                      }}
                    >
                      <ArrowDown
                        width={13}
                        height={13}
                        color={index === 0 ? currentTheme.palette.blueMedium : currentTheme.palette.blue}
                      />
                    </TabButtonPrevious>
                    {tab.title}
                    <TabButtonNext
                      onClick={(e: any) => {
                        if (index === tabs.length - 1) {
                          return;
                        }
                        e.stopPropagation();
                        setSelectedTab(index + 1);
                      }}
                    >
                      <ArrowDown
                        width={13}
                        height={13}
                        color={index === tabs.length - 1 ? currentTheme.palette.blueMedium : currentTheme.palette.blue}
                      />
                    </TabButtonNext>
                  </TabButton>
                );
              })}
            </Tabs>
          )}
          <TabsWrapper ref={tabBodyRef}>
            {tabs.map((tab: InsertTab, index: number) => (
              <TabBody key={`tab-${index}`} className={selectedTab === index ? 'mod-visible' : ''}>
                <WrapperItems>{formatItems(getItemsFromTab(tab))}</WrapperItems>
                {tab.sectionLink && (
                  <Cta href={tab.sectionLink.link} className="mod-blue mod-empty mod-icon mod-sm">
                    {tab.sectionLink.label}
                    <ArrowPlainRight width={13} height={13} color="var(--theme-palette-blue)" />
                  </Cta>
                )}
              </TabBody>
            ))}
          </TabsWrapper>
        </SelectedItemsWrapper>
      </FullContentRelative>
    </SelectedItemsLineContainer>
  );
};
