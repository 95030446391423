import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../../utils/mq';
import { useImageComponent, useLinkComponent } from '../../utils/component';
import type { LinkProps } from '../../atoms/types';
import { useTranslation } from '../../utils/translation';
import { displayDateDiff } from '../../atoms/date/utils';
import Comment from '../../icons/comment';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentsNb?: number;
  tag?: string;
  authors?: AuthorProp[];
  sponsored?: boolean;
  mainTag?: string;
}

const Post = styled.article`
  color: ${(props) => props.theme.colors.foreground};
  display: flex;
  height: 350px;
  position: relative;
  width: 100%;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      height: initial;
      flex-direction: column;
    `,
  )}
`;

const Wrapper = styled.div`
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.4) 90%,
    rgba(0, 0, 0, 0.5) 100%
  );
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  justify-content: flex-start;
  margin: 0 0 auto;
  padding: var(--spacer) var(--spacer-m);
  z-index: 1;
  width: 100%;
  height: 100%;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      background-image: none;
      border-radius: 0;
      margin: var(--spacer-s) 0 0;
      padding: 0;
    `,
  )}
`;

const WrapperImage = styled.div`
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  picture {
    width: 100%;
  }

  img {
    object-fit: cover;
  }

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      position: relative;
      margin: 0 calc(-1 * var(--spacer-s));

      img {
        border-radius: 0;
      }
    `,
  )}
`;

const Title = styled.p`
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 20px;
  font-weight: 700;
  line-height: 1.38;
  transition: color 0.3s;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      color: white;
      font-size: 26px;
    `,
  )}
`;

const Informations = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 12px;
  gap: var(--spacer-xs);
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      color: white;
      font-size: 14px;
    `,
  )}
`;

const PublishDate = styled.span``;

const AuthorLabel = styled.span``;

const Comments = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;
`;

const Sponsored = styled.span``;

const Label = styled.div`
  align-self: start;
  background: rgba(0, 0, 0, 0.3);
  border: solid 2px white;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.62;
  padding: 3px 12px;
  text-transform: uppercase;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: -55px 0 5px;
    `,
  )}
`;
const Filler = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-grow: 1;
    `,
  )}
`;

export const MajorItemShowcase: any = (props: PostItemProps) => {
  const { sponsored, image, title, date, link, authors, commentsNb, mainTag } = props;

  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const t = useTranslation();

  const imageProps = {
    ...image,
    mobileWidth: 320,
    mobileHeight: 274,
    desktopWidth: 540,
    desktopHeight: 350,
  };

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(date, locale);

  return (
    <LinkComponent {...link}>
      <Post>
        <WrapperImage>
          <ImageComponent {...imageProps} />
        </WrapperImage>
        <Wrapper>
          {mainTag ? <Label>{mainTag}</Label> : null}
          <Filler />
          <Title data-testid="meta-label">{title}</Title>
          <Informations>
            <PublishDate data-testid="meta-date">{dateTimeAgo},</PublishDate>
            {authors && (
              <AuthorLabel>
                {t('design.postItemShowcase.authorLabel')} <strong>{authors}</strong>
              </AuthorLabel>
            )}
            {commentsNb > 0 && (
              <Comments>
                <Comment width={24} height={24} color="var(--theme-color-primary)" />
                {commentsNb}
              </Comments>
            )}
            {sponsored && !commentsNb && <Sponsored>(sponsorisé)</Sponsored>}
          </Informations>
        </Wrapper>
      </Post>
    </LinkComponent>
  );
};
