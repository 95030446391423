import React from 'react';
import { LogoProps } from './types';

function ArrowPlainRight({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00065 0.833984L5.82565 2.00898L10.4757 6.66732H0.333984V8.33398H10.4757L5.82565 12.9923L7.00065 14.1673L13.6673 7.50065L7.00065 0.833984Z"
        fill={color}
      />
    </svg>
  );
}

export default ArrowPlainRight;
