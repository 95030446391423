import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { useTranslation } from '../utils/translation';
import { displayDateDiff } from '../atoms/date/utils';
import Comment from '../icons/comment';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}
export interface PostItemTitleProps {
  title: string;
  link: LinkProps;
  authors?: AuthorProp[];
  date: string;
  commentsNb?: number;
}

const Post = styled.article`
  border-top: solid 1px var(--color-alt-2);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.38;
  padding: var(--spacer-s-fluid) 0 var(--spacer-s-fluid);

  &:first-of-type {
    border-top: none;
  }

  a {
    transition: color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  ${mq.lte(
    DEVICE_SIZE.MEDIUM,
    css`
      display: flex;
      font-size: 16px;
      line-height: 1.62;
      gap: var(--spacer-xs);
      flex-direction: column;
    `,
  )}
`;

const PublishDate = styled.p`
  color: var(--theme-colors-foreground);
`;

const Comments = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: var(--spacer-xs);
  margin: 0 0 0 auto;
`;

const Informations = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-family: var(--theme-typography-default-font);
  font-size: 12px;
  font-weight: 400;
  gap: 4px;
  justify-content: flex-start;
  margin: var(--spacer-xs) 0 0;
`;

const AuthorLabel = styled.span``;

export const PostItemTitle: any = (props: PostItemTitleProps) => {
  const { title, link, authors, date, commentsNb } = props;

  const LinkComponent = useLinkComponent();
  const t = useTranslation();

  const locale = t('locale');
  const dateTimeAgo = displayDateDiff(date, locale);

  return (
    <Post>
      <LinkComponent {...link}>{title}</LinkComponent>
      <Informations>
        <PublishDate data-testid="meta-date">{dateTimeAgo},</PublishDate>
        {authors && (
          <AuthorLabel>
            {t('design.postItemShowcase.authorLabel')} <strong>{authors}</strong>
          </AuthorLabel>
        )}
        {commentsNb > 0 && (
          <Comments>
            <Comment width={24} height={24} color="var(--theme-color-primary)" />
            {commentsNb}
          </Comments>
        )}
      </Informations>
    </Post>
  );
};
