import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../utils/translation';
import { useLinkComponent } from '../../utils/component';
import { DEVICE_SIZE, mq } from '../../utils/mq';
import { LinkProps } from '../../atoms/types';
import Quote from '../../icons/quote';

export type CommentCardProps = {
  pseudo: string;
  avatar: string;
  comment: string;
  createdAt?: string;
  link?: LinkProps;
  title?: string;
};

const AvatarImg = styled.img`
  border-radius: 50%;
`;

const Card = styled.div`
  align-self: stretch;
  margin-bottom: var(--spacer-m);
  background: var(--theme-color-background);
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  line-height: 1.62;
  max-width: 392px;
  min-width: 392px;
  padding: var(--spacer-fluid);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      min-width: 80vw;
    `,
  )}
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  gap: var(--spacer-s);

  img {
    border-radius: 50%;
  }
`;

const Pseudo = styled.span`
  font-family: var(--theme-typography-primary-font);
  font-size: 22px;
  margin: 0 auto 0 0;
`;

const Bottom = styled.div`
  color: var(--theme-color-medium);

  a {
    color: var(--title-foreground);
    font-weight: 700;
    text-decoration: underline;
  }
`;

const CommentText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
`;

export function CommentCard({ pseudo, avatar, comment, createdAt, link, title }: CommentCardProps) {
  const t = useTranslation();
  const LinkComponent = useLinkComponent();

  return (
    <Card>
      <Header>
        <AvatarImg src={`https://forum.clubic.com/${avatar}`} height={60} alt="username" />
        <Pseudo>{pseudo}</Pseudo>
        <Quote width={30} height={23} color="var(--theme-color-primary)" />
      </Header>
      <CommentText dangerouslySetInnerHTML={{ __html: comment }} />
      <Bottom>
        {t('design.commentCard.postedAt', { createdAt })} <LinkComponent {...link}>{title}</LinkComponent>
      </Bottom>
    </Card>
  );
}
